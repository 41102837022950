@import './font';

* {
    box-sizing: border-box;
    margin: 0;
}

body {
    margin: 0;
    background: #420807;

    #Main {
        background: url('https://cdngarenanow-a.akamaihd.net/webid/FF/distributors/bg.jpg') center top no-repeat;
        background-size: 100%;
        height: 100vh;
        width: 100vw;
        
        @media screen and (orientation: landscape) {
            min-height: 100vh;
        }
    
        @import './navbar';

        .content-container {
            width: 100%;

            .container {
                padding: 0 5%;
                width: 100%;
        
                .ff--distributor {
                    &_header {
                        text-align: center;
                        
                        >p {
                            color: #ffffff;
                            font-family: bebas;
                            letter-spacing: .5px;
                            font-size: 5vw;
                            width: 50%;
                            margin: auto;
                        }
                    }
        
                    &_box {
                        margin-top: 8%;
                        min-height: 75vh;
                        background: url('https://cdngarenanow-a.akamaihd.net/webid/FF/distributors/bg-box.png') center no-repeat;
                        background-size: 100% 100%;
                        
                        @media screen and (orientation: landscape) {
                            min-height: fit-content;
                        }
        
                        .content {
                            height: fit-content;
                            display: flex;
                            flex-direction: column;
                            margin: 0 5%;
                            padding: 3% 0;

                            .container-country {
                                display: flex;
                                flex-direction: column;
                                margin-bottom: 3vmin;
                            }
        
                            .country {
                                height: 10vmin;
                                text-align: center;

                                // @media screen and (orientation: landscape) {
                                //     height: 15vmin;
                                // }
        
                                .flag {
                                    height: 50%;
        
                                    >img {
                                        max-height: 100%;
                                        vertical-align: top;
                                    }
                                }
        
                                .name {
                                    height: 50%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-transform: uppercase;
        
                                    >span {
                                        font-size: 3vw;
                                        font-family: futura;
                                    }
                                }
                            }
        
                            .distributor {
                                height: fit-content;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                border-bottom: .1vmin solid #828282;

                                .brand {
                                    width: 20%;
                                    height: 15vmin;
                                    margin: 1%;

                                    @media screen and (orientation: landscape) {
                                        height: 25vmin;
                                    }
        
                                    .logo {
                                        height: 70%;
                                        background: #ffffff;
                                        border-radius: 5px;
                                        text-align: center;
        
                                        >img {
                                            max-height: 80%;
                                            max-width: 90%;
                                            position: relative;
                                            top: 50%;
                                            transform: translateY(-50%);
                                        }
                                    }
        
                                    .name {
                                        text-align: center;
                                        height: 30%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
        
                                        >span {
                                            font-size: 1.8vw;
                                            font-family: futura;
                                        }
                                    }
                                }
                            }
                        }
                    }
        
                    &_footer {
                        width: 100%;
                        height: 3vmax;
                        text-align: center;
                        margin: 4% 0;
                        
                        >img {
                            max-height: 100%;
                        }
                    }
                }
            }
        }
    }
}