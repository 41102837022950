.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5vmax;
    padding: 0 5%;

    .ff-logo {
        height: 40%;

        >img {
            max-height: 100%;
            position: relative;
        }
    }

    .lang-toggle {
        font-size: 3vmin;
        text-align: center;
        color: #ffffff;
        font-family: futura;
        
        >a {
            color: #ffffff;
            text-decoration: none;

            &.active {
                color: gold;
            }
        }
    }
}