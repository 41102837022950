@font-face {
  font-family: bebas;
  src: url("https://cdngarenanow-a.akamaihd.net/webid/FF/distributors/fonts/BEBAS.ttf"); }

@font-face {
  font-family: futura;
  src: url("https://cdngarenanow-a.akamaihd.net/webid/FF/distributors/fonts/FUTURASTD-BOOK_0.OTF"); }

* {
  box-sizing: border-box;
  margin: 0; }

body {
  margin: 0;
  background: #420807; }
  body #Main {
    background: url("https://cdngarenanow-a.akamaihd.net/webid/FF/distributors/bg.jpg") center top no-repeat;
    background-size: 100%;
    height: 100vh;
    width: 100vw; }
    @media screen and (orientation: landscape) {
      body #Main {
        min-height: 100vh; } }
    body #Main .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 5vmax;
      padding: 0 5%; }
      body #Main .navbar .ff-logo {
        height: 40%; }
        body #Main .navbar .ff-logo > img {
          max-height: 100%;
          position: relative; }
      body #Main .navbar .lang-toggle {
        font-size: 3vmin;
        text-align: center;
        color: #ffffff;
        font-family: futura; }
        body #Main .navbar .lang-toggle > a {
          color: #ffffff;
          text-decoration: none; }
          body #Main .navbar .lang-toggle > a.active {
            color: gold; }
    body #Main .content-container {
      width: 100%; }
      body #Main .content-container .container {
        padding: 0 5%;
        width: 100%; }
        body #Main .content-container .container .ff--distributor_header {
          text-align: center; }
          body #Main .content-container .container .ff--distributor_header > p {
            color: #ffffff;
            font-family: bebas;
            letter-spacing: .5px;
            font-size: 5vw;
            width: 50%;
            margin: auto; }
        body #Main .content-container .container .ff--distributor_box {
          margin-top: 8%;
          min-height: 75vh;
          background: url("https://cdngarenanow-a.akamaihd.net/webid/FF/distributors/bg-box.png") center no-repeat;
          background-size: 100% 100%; }
          @media screen and (orientation: landscape) {
            body #Main .content-container .container .ff--distributor_box {
              min-height: -webkit-fit-content;
              min-height: -moz-fit-content;
              min-height: fit-content; } }
          body #Main .content-container .container .ff--distributor_box .content {
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            margin: 0 5%;
            padding: 3% 0; }
            body #Main .content-container .container .ff--distributor_box .content .container-country {
              display: flex;
              flex-direction: column;
              margin-bottom: 3vmin; }
            body #Main .content-container .container .ff--distributor_box .content .country {
              height: 10vmin;
              text-align: center; }
              body #Main .content-container .container .ff--distributor_box .content .country .flag {
                height: 50%; }
                body #Main .content-container .container .ff--distributor_box .content .country .flag > img {
                  max-height: 100%;
                  vertical-align: top; }
              body #Main .content-container .container .ff--distributor_box .content .country .name {
                height: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase; }
                body #Main .content-container .container .ff--distributor_box .content .country .name > span {
                  font-size: 3vw;
                  font-family: futura; }
            body #Main .content-container .container .ff--distributor_box .content .distributor {
              height: -webkit-fit-content;
              height: -moz-fit-content;
              height: fit-content;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              border-bottom: .1vmin solid #828282; }
              body #Main .content-container .container .ff--distributor_box .content .distributor .brand {
                width: 20%;
                height: 15vmin;
                margin: 1%; }
                @media screen and (orientation: landscape) {
                  body #Main .content-container .container .ff--distributor_box .content .distributor .brand {
                    height: 25vmin; } }
                body #Main .content-container .container .ff--distributor_box .content .distributor .brand .logo {
                  height: 70%;
                  background: #ffffff;
                  border-radius: 5px;
                  text-align: center; }
                  body #Main .content-container .container .ff--distributor_box .content .distributor .brand .logo > img {
                    max-height: 80%;
                    max-width: 90%;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%); }
                body #Main .content-container .container .ff--distributor_box .content .distributor .brand .name {
                  text-align: center;
                  height: 30%;
                  display: flex;
                  align-items: center;
                  justify-content: center; }
                  body #Main .content-container .container .ff--distributor_box .content .distributor .brand .name > span {
                    font-size: 1.8vw;
                    font-family: futura; }
        body #Main .content-container .container .ff--distributor_footer {
          width: 100%;
          height: 3vmax;
          text-align: center;
          margin: 4% 0; }
          body #Main .content-container .container .ff--distributor_footer > img {
            max-height: 100%; }

.style_wp_pop__2wpkV {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
  overflow: hidden;
}

.style_wp_content__2uua0 {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.style_wp_pop_img__1SxxF {
  width: 25vw;
  height: 16vw;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  animation: style_rotateA__2tdM4 1.5s ease infinite alternate;
}

.style_wp_tips__QHo73 {
  width: 60%;
  font-size: 2vw;
  color: #FFFFFF;
  text-align: center;
  margin: 6vw auto 0;
}

@keyframes style_rotateA__2tdM4 {
  0% {
    transform: rotate(0deg); }
  30% {
    transform: rotate(0deg); }
  70% {
    transform: rotate(-90deg); }
  100% {
    transform: rotate(-90deg); } }

@media screen and (max-width: 820px) and (orientation: portrait) {
  .style_portrait__1Mlr3 {
    display: block; 
  } 
}

@media screen and (max-width: 820px) and (orientation: landscape) {
  .style_landscape__1L-FY {
    display: block;
  } 
}

@media screen and (min-width: 820px) and (orientation: landscape) {
  .style_desktop_landscape__nB-uH {
    display: block; } }
.style_overlay__1FTMa{
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.6);
	text-align: center;
	display: none;
	top: 0;
	left: 0;
}

.style_img__3alEc{
	position: relative;
	width: 33%;
	top: 50%;
	transform: translateY(-50%);
}

.style_active__2SO3u{
	display: block;
}
